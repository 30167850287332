<template>
  <page-layout>
    <div
      style="display:inline-block;background-color:rgba(22,132,251,0.10)"
      class="mb-16"
    >
      <p style="color:#808080;line-height:32px;" class="px-16">
        发票将以快递（免费）方式寄给您，当前已添加<span style="color:#1684FB">{{
          addressNum.listLen
        }}</span
        >条地址，还能保存<span style="color:#1684FB">{{
          addressNum.total - addressNum.listLen
        }}</span
        >条地址
      </p>
    </div>
    <address-list
      @handlerChange="handlerChange"
      @handlerListChange="handlerListChange"
    ></address-list>
  </page-layout>
</template>
<script>
import { defineComponent, ref, isRef } from "vue";
import addressList from "../../ApplyInvoice/components/addressList.vue";

export default defineComponent({
  setup() {
    const handlerChange = data => {
      if (isRef(data)) {
        console.log(data);
      }
      // ctx.emit("updateDate", addressId);
    };

    const addressNum = ref({
      total: 5,
      listLen: 0
    });

    //addressList 变化
    const handlerListChange = data => {
      addressNum.value.listLen = data.value?.length | 0;
    };

    return {
      handlerChange,
      addressNum,
      handlerListChange
    };
  },
  components: { addressList }
});
</script>
<style lang="less" scoped>
:deep(.addressBox) li:nth-of-type(3n) {
  margin-right: 16px;
}
</style>
