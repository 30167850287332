<template>
  <div>
    <!-- 发票管理 -->
    <tabs-list
      :list="list"
      :currentIndex="currentIndex"
      @tabIndex="handleGetTabIndex"
    />
    <div class="mx-24 my-16">
      <!--  开票申请列表 -->
      <!-- <make-invoice-list v-if="currentIndex === 0" /> -->
      <!-- 发票列表 -->
      <make-invoice-records v-if="currentIndex === 0" />
      <!-- 开票信息 -->
      <make-invoice-info v-if="currentIndex === 1" />
      <!-- 地址管理 -->
      <address-manager v-if="currentIndex === 2" />
    </div>
  </div>
</template>
<script>
import { defineComponent, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import TabsList from "./components/tabsList";
// import MakeInvoiceList from "./components/makeInvoiceList";
import MakeInvoiceRecords from "./components/makeInvoiceRecords";
import MakeInvoiceInfo from "./components/makeInvoiceInfo";
import AddressManager from "./components/addressManager";

export default defineComponent({
  name: "service-invoice",
  components: {
    TabsList,
    // MakeInvoiceList,
    MakeInvoiceRecords,
    MakeInvoiceInfo,
    AddressManager
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const currentIndex = ref(0);
    if (route.query?.next) {
      console.log("route.query.next", route.query.next);
      currentIndex.value = +route.query.next;
    }
    // const stateSwitch = next => {};

    if (route.query.state !== undefined) {
      currentIndex.value = +route.query.state;
      // router.push({
      //   path: "/invoice/service-invoice",
      //   query: { state: +route.query.state }
      // });
    }
    const handleGetTabIndex = index => {
      currentIndex.value = +index;
      router.push({ path: "/invoice/service-invoice", query: { next: index } });
      // router.push({
      //   path: "/invoice/service-invoice",
      //   query: { state: index }
      // });
    };

    const list = ref([
      // { id: 1, value: "开票申请" },
      { id: 2, value: "发票列表" },
      { id: 3, value: "开票信息" },
      { id: 4, value: "地址管理" }
    ]);

    return {
      list,
      currentIndex,
      // stateSwitch,
      handleGetTabIndex
    };
  }
});
</script>
