<template>
  <page-layout style="background:#fff;" class="px-30 py-20">
    <a-form
      style="margin:0 auto;overflow: hidden;padding-top: 10px;"
      :model="companyInvoiceInfo"
      :label-col="{ style: 'width: 100px' }"
      :wrapper-col="{ span: 20 }"
    >
      <a-form-item label="发票抬头" name="name">
        {{ companyInvoiceInfo.name || "-" }}
      </a-form-item>
      <a-form-item label="纳税人识别号" name="tax_number">
        {{ companyInvoiceInfo.tax_number || "-" }}
      </a-form-item>
      <a-form-item label="开户银行名称" name="buyer_bank">
        {{ companyInvoiceInfo.buyer_bank || "-" }}
      </a-form-item>
      <a-form-item label="开户银行账号" name="buyer_bank_account">
        {{ companyInvoiceInfo.buyer_bank_account || "-" }}
      </a-form-item>
      <a-form-item label="企业注册地址" name="buyer_address">
        {{ companyInvoiceInfo.buyer_address || "-" }}
      </a-form-item>
      <a-form-item label="企业联系电话" name="contact_phone">
        {{ companyInvoiceInfo.contact_phone || "-" }}
      </a-form-item>
    </a-form>
    <div class="text-center">
      <a-button
        v-if="$store.getters.roles.includes('InvoicePerfectInfo')"
        type="primary"
        @click="showCompanyInfoModal = true"
        >完善信息</a-button
      >
    </div>
    <!-- 完善信息表单 -->
    <a-modal
      ref="companyInfoModal"
      v-model:visible="showCompanyInfoModal"
      title="完善开票信息"
      @cancel="handleCancel"
      @ok="handleOk"
      okText="保存"
    >
      <a-form
        ref="formRef2"
        :model="stateRef"
        :rules="rules"
        :label-col="{ span: 5 }"
        :wrapper-col="{ span: 19 }"
      >
        <a-form-item label="发票抬头">
          <a-input
            disabled
            v-model:value.trim="stateRef.name"
            placeholder="请输入发票抬头"
          ></a-input>
        </a-form-item>
        <a-form-item label="纳税人识别号">
          <a-input
            disabled
            v-model:value.trim="stateRef.tax_number"
            placeholder="请输入纳税人识别号"
          ></a-input>
        </a-form-item>
        <a-form-item label="开户银行名称" name="buyer_bank">
          <a-input
            v-model:value.trim="stateRef.buyer_bank"
            placeholder="请输入开户银行名称"
          ></a-input>
        </a-form-item>
        <a-form-item label="开户账号" name="buyer_bank_account">
          <a-input
            v-model:value.trim="stateRef.buyer_bank_account"
            placeholder="请输入开户账号"
          ></a-input>
        </a-form-item>
        <a-form-item label="企业注册地址" name="buyer_address">
          <a-textarea
            v-model:value.trim="stateRef.buyer_address"
            :maxlength="120"
            placeholder="请输入详细地址信息、如道路、门牌号、小区、楼栋号、单元等信息"
          ></a-textarea>
          <p class="fz-12" style="color:#FF9800">
            注：详细地址长度需要在5-120个汉字或字符，不能包含表情符号
          </p>
        </a-form-item>
        <a-form-item label="企业联系电话" name="contact_phone">
          <a-input
            v-model:value.trim="stateRef.contact_phone"
            placeholder="请输入企业联系电话"
          ></a-input>
        </a-form-item>
      </a-form>
    </a-modal>
  </page-layout>
</template>
<script>
import { defineComponent, ref, watch, onMounted } from "vue";
import { state } from "@/store/modules/user";
import { Message } from "ant-design-vue";
import {
  useQueryCompanyDetailApi,
  useUpdateCompanyDetailApi
} from "@/apis/account";

export default defineComponent({
  setup() {
    //改版
    const queryCompanyDetail = useQueryCompanyDetailApi();
    const companyInvoiceInfo = ref({});
    const getCompanyInfo = async () => {
      const res = await queryCompanyDetail({
        company_id: state.companyId
      });
      companyInvoiceInfo.value = res;
      //初始化企业信息表单数据
      Object.keys(res).forEach(item => {
        stateRef.value[item] = res[item];
      });
    };

    onMounted(() => {
      getCompanyInfo();
    });

    //企业地址
    const watchAddress = ref({});
    watch(
      companyInvoiceInfo,
      () => {
        if (
          companyInvoiceInfo.value.province &&
          companyInvoiceInfo.value.city &&
          companyInvoiceInfo.value.area
        ) {
          watchAddress.value = `${companyInvoiceInfo.value.province}/${companyInvoiceInfo.value.city}/${companyInvoiceInfo.value.area}`;
        } else {
          watchAddress.value = "";
        }
      },
      { immediate: true }
    );

    // 省市区选择
    const handleCityCheck = value => {
      stateRef.value.province = value[0]; //省
      stateRef.value.city = value[1]; //市
      stateRef.value.area = value[2]; //区
    };

    //完善信息Modal
    const formRef2 = ref();
    const showCompanyInfoModal = ref(false);
    const updateCompanyDetail = useUpdateCompanyDetailApi();
    const handleCancel = () => {
      formRef2.value.resetFields();
    };
    const handleOk = async () => {
      await formRef2.value.validate();
      handleOkAgain();
    };
    const handleOkAgain = async () => {
      let data = {
        ...companyInvoiceInfo.value,
        ...stateRef.value,
        company_id: state.companyId,
        buyer_phone: companyInvoiceInfo.value.contact_phone
      };
      await updateCompanyDetail(data);
      Message.success("修改成功!");
      showCompanyInfoModal.value = false;
      getCompanyInfo();
    };

    //完善信息弹窗数据
    const stateRef = ref({
      name: "",
      tax_number: "",
      buyer_bank: "",
      buyer_bank_account: "",
      buyer_address: "",
      contact_phone: "",
      province: "",
      city: "",
      area: ""
    });
    //企业地址验证规则
    let validateAddress = () => {
      if (
        stateRef.value.province &&
        stateRef.value.city &&
        stateRef.value.area
      ) {
        return Promise.resolve();
      } else {
        return Promise.reject("企业信息不能为空");
      }
    };
    const rules = {
      buyer_bank: [
        { required: true, message: "开户银行名称不能为空", trigger: "blur" }
      ],
      buyer_bank_account: [
        {
          required: true,
          message: "开户银行账号不能为空",
          trigger: "blur"
        }
      ],
      address: [
        {
          required: true,
          message: "请选择企业注册地址",
          validator: validateAddress
        }
      ],
      buyer_address: [
        {
          required: true,
          message: "企业详细地址不能为空",
          trigger: "blur"
        },
        {
          min: 5,
          max: 120,
          message: "企业地址长度需在5-120个汉字或字符",
          trigger: "blur"
        }
      ],
      contact_phone: [
        {
          required: true,
          message: "企业联系电话不能为空",
          trigger: "blur"
        }
      ]
    };

    return {
      companyInvoiceInfo,
      formRef2,
      showCompanyInfoModal,
      handleCancel,
      handleOk,
      handleOkAgain,
      stateRef,
      rules,
      labelCol: { span: 8 },
      wrapperCol: { span: 16 },
      watchAddress,
      handleCityCheck,
      getCompanyInfo
    };
  }
});
</script>
