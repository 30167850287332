<template>
  <page-layout class="mt-20">
    <!-- 搜索头 -->
    <search-header
      :schemaData="searchFileds"
      @getDataList="handleFormSearch"
      style="padding-left: 0px;"
    />
    <!-- 列表 -->
    <div class="mt-12 bg-white">
      <div
        class="px-24 py-16"
        style="border:1px solid #f3f4f6;border-bottom:1px solid #f3f4f6;"
      >
        <a-button
          v-if="$store.getters.roles.includes('ApplyInvoice')"
          type="primary"
          @click="handleApplyInvoice"
          >申请开票</a-button
        >
      </div>

      <p-table
        rowKey="id"
        dataKey="modal"
        :dataSource="dataSource"
        :columns="tableColumns.columnsRecords"
        class="bg-white"
        ><template #footer>
          <div
            class="d-flex justify-space-start align-center"
            style="width:100%;"
          >
            <!-- -->
            <p style="color:#808080" class="ml-12 mr-24">
              可开票金额：{{
                (totalAmount.invoicable_amount / 100).toFixed(2)
              }}元
            </p>
            <p style="color:#808080" class="ml-12 mr-24">
              已开票金额：{{ (totalAmount.invoiced_amount / 100).toFixed(2) }}元
            </p>
            <p style="color:#808080" class="ml-12 mr-24">
              待审核金额：{{
                (totalAmount.pending_invoice_amount / 100).toFixed(2)
              }}元
            </p>
            <p style="color:#808080" class="ml-12 mr-24">
              截至上月消费金额：{{
                (totalAmount.consumed_amount / 100).toFixed(2)
              }}元
            </p>
          </div>
        </template></p-table
      >
    </div>
  </page-layout>
</template>
<script>
import { defineComponent, reactive, ref } from "vue";
import { useRouter } from "vue-router";
import { getInitTable } from "@/hooks/form-hook";
import { Table } from "@wlhy-web-components/common";
import { searchFileds } from "../constants/index";
import { getColumns } from "../constants/table-config";
import useSearchForm from "@/hooks/UseSearchForm";
import { Tooltip } from "ant-design-vue";
import { QuestionCircleOutlined } from "@ant-design/icons-vue";
import {
  useInvoiceRecordApi /* useInvoiceAccountTotalApi */
} from "@/apis/service";

export default defineComponent({
  name: "spending-bills",
  setup() {
    const router = useRouter();
    const tableDataSource = reactive(getInitTable());
    const { filter, handleFormSearch } = useSearchForm(searchFileds);

    const invoiceRecord = useInvoiceRecordApi();
    //可开票金额：{{ totalAmount }}元
    const totalAmount = ref({});
    const getConsultingList = Table.useTableConfig({
      filter,
      dataSource: tableDataSource,
      async fetchData() {
        const data = {
          ...filter.value,
          size: tableDataSource.meta.pageSize,
          page: tableDataSource.meta.currentPageNo
        };
        const res = await invoiceRecord(data);
        totalAmount.value = res.ext.account_invoice_sum;
        return { records: res.list, total: res.total };
      }
    });

    // 跳转到开票记录详情
    const handleActionClick = row => {
      console.log(row);
      let details = {
        id: row.id,
        name: row.apply_user,
        time: row.apply_time,
        total: row.amount
      };
      router.push({
        path: "/invoice/invoice-detail",
        query: details
      });
    };

    // 点击确认订单，所在行数据消失---调用接口刷新页面
    const handleConfirmClick = row => {
      console.log(row);
    };
    //申请开票
    const handleApplyInvoice = () => router.push({ name: "ApplyInvoice" });

    // 修改、
    const handleRetuen = records => {
      console.log(records);
      router.push({ name: "ApplyInvoice", params: { ...records } });
    };
    const tableColumns = reactive(getColumns());
    // 操作列渲染逻辑
    const invoiceProofNumber = tableColumns.columnsRecords.find(
      v => v.dataIndex === "status_desc"
    );
    invoiceProofNumber.customRender = (text, records) => {
      if (records.status === "RETURN") {
        return (
          <div>
            <Tooltip
              placement="top"
              title={records.ext_json?.remark}
              overlayStyle={{ width: "140px" }}
            >
              <p style="display: inline-block">
                <span class="mr-8">{text}</span>
                <QuestionCircleOutlined
                  class="fz-14 mr-8"
                  style="color: #1684fb"
                />
              </p>
            </Tooltip>
            <span
              style="color: #1684fb; cursor: pointer"
              onClick={() => handleRetuen(records)}
            >
              修改
            </span>
          </div>
        );
      } else {
        return <span>{text}</span>;
      }
    };

    return {
      totalAmount,
      tableColumns,
      searchFileds,
      handleRetuen,
      getConsultingList,
      handleFormSearch,
      handleActionClick,
      handleConfirmClick,
      handleApplyInvoice,
      dataSource: tableDataSource
    };
  }
});
</script>
