/**
 * 搜索头
 */
//  开票记录
export const searchFileds = [
  {
    field: "id",
    label: "申请ID",
    type: "input",
    iw: 200
  },
  {
    type: "select",
    field: "status",
    label: "状态",
    options: [
      { value: "PENDING", label: "待审核" },
      { value: "APPROVED", label: "通过" }
    ],
    iw: 200
  },
  {
    field: ["start_time", "end_time"],
    label: "申请时间",
    type: "dateRange",
    props: {
      placeholder: ["开始时间", "结束时间"],
      dateFormat: "YYYY/MM/DD"
    },
    iw: 320
  }
];
