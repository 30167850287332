<template>
  <div>
    <div class="pa-16 d-flex justify-flex-start align-center tab">
      <span v-if="type === 0" style="color:#3C4E64" class="fz-16 mr-16 fw-500"
        >发票管理</span
      >
      <i
        v-if="type === 0"
        style="height:24px;width:1px;background:#D4D4D4;display:block;"
        class="mr-48"
      ></i>
      <div
        class="d-flex justify-flex-start mr-48"
        :class="{
          'color-primary fw-500': index === currentIndex
        }"
        v-for="(item, index) in tabList"
        :key="index"
        style="cursor:pointer;color:#808AA4"
        @click="handleChangeTab(index)"
      >
        <span>{{ item.value }}</span>
        <span
          v-if="currentIndex === index"
          class="tab-border-line bg-primary"
          :style="{
            transform: 'translateX(' + (currentIndex * 1 + 13) + 'px)'
          }"
        ></span>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent, ref } from "vue";

export default defineComponent({
  props: {
    type: {
      type: Number,
      default: 0
    },
    list: Array,
    currentIndex: Number
  },
  setup(props, context) {
    // const list = ref([
    //   { value: "开票申请" },
    //   { value: "开票记录" },
    //   { value: "开票信息" },
    //   { value: "地址管理" }
    // ]);
    const tabList =
      props.type === 0
        ? props.list
        : ref([{ value: "开票信息" }, { value: "邮寄地址" }]);

    const handleChangeTab = index => {
      context.emit("tabIndex", index);
    };

    return {
      tabList,
      // currentIndex,
      handleChangeTab
    };
  }
});
</script>
<style lang="less" scoped>
.tab {
  position: relative;
  background: #fff;
  border: none;
  .tab-border-line {
    height: 2px;
    width: 28px;
    position: absolute;
    bottom: 0;
    border: none;
    color: #1684fb;
    transition: all cubic-bezier(0.645, 0.045, 0.355, 1) 0.3s;
  }
}
</style>
